<template>
    <v-app>
        <div class="row">
            <div class="col">
                <v-card>
                    <v-card-title>
                        <span>Pedido #{{orden_id}}</span>
                    </v-card-title>
                    <v-card-text>
                        <div class="row">
                            <div class="col">
                                <div class="kt-portlet">
                                    <div class="kt-portlet__body  kt-portlet__body--fit">
                                        <div class="row row-no-padding row-col-separator-xl">
                                            <div class="col-sm-12 col-md-4 col-lg-2 col-xl-2">
                                                <v-card height="131px" outlined tile >
                                                    <v-card-text style="height: 100%" class="d-flex flex-column justify-space-between">
                                                        <div class="d-flex flex-column">
                                                            <span class="text-subtitle-1 font-weight-medium">Número de pedido</span>
                                                            <span class="text-body-2">Interno</span>
                                                        </div>
                                                        <span style="font-size: 1.2rem !important;" class="text-subtitle-1 kt-font-brand font-weight-medium">
                                                            {{orden_id}}
                                                        </span>
                                                    </v-card-text>
                                                </v-card>
                                            </div>
                                            <div class="col-sm-12 col-md-4 col-lg-2 col-xl-2">
                                                <v-card height="131px" outlined tile >
                                                    <v-card-text style="height: 100%" class="d-flex flex-column justify-space-between">
                                                        <div class="d-flex flex-column">
                                                            <span class="text-subtitle-1 font-weight-medium">Referencia</span>
                                                            <span class="text-body-2">Fuente</span>
                                                        </div>
                                                        <span style="font-size: 1.2rem !important;" class="text-subtitle-1 kt-font-warning font-weight-medium">
                                                            {{order.order_reference}}
                                                        </span>
                                                    </v-card-text>
                                                </v-card>
                                            </div>
                                            <div class="col-sm-12 col-md-4 col-lg-2 col-xl-2">
                                                <v-card height="131px" outlined tile >
                                                    <v-card-text style="height: 100%" class="d-flex flex-column justify-space-between">
                                                        <div class="d-flex flex-column">
                                                            <span class="text-subtitle-1 font-weight-medium">Fuente</span>
                                                            <span class="text-body-2">Tienda/Marketplace</span>
                                                        </div>
                                                        <span style="font-size: 1.2rem !important;" class="text-subtitle-1 kt-font-danger font-weight-medium">
                                                            {{source.name}}
                                                        </span>
                                                    </v-card-text>
                                                </v-card>
                                            </div>
                                            <div class="col-sm-12 col-md-4 col-lg-2 col-xl-2">
                                                <v-card height="131px" outlined tile >
                                                    <v-card-text style="height: 100%" class="d-flex flex-column justify-space-between">
                                                        <div class="d-flex flex-column">
                                                            <span class="text-subtitle-1 font-weight-medium">Fecha</span>
                                                            <span class="text-body-2">Ingreso</span>
                                                        </div>
                                                        <span style="font-size: 1.2rem !important;" class="text-subtitle-1 kt-font-success font-weight-medium">
                                                            {{$moment(order.source_date).format('DD-MM-YYYY HH:mm')}}
                                                        </span>
                                                    </v-card-text>
                                                </v-card>
                                            </div>
                                            <div class="col-sm-12 col-md-4 col-lg-2 col-xl-2">
                                                <v-card height="131px" outlined tile >
                                                    <v-card-text style="height: 100%" class="d-flex flex-column justify-space-between">
                                                        <div class="d-flex flex-column">
                                                            <span class="text-subtitle-1 font-weight-medium">Estado de orden</span>
                                                            <span class="text-body-2">Actual</span>
                                                        </div>
                                                        <span style="font-size: 1.2rem !important;" class="text-subtitle-1 purple--text font-weight-medium">
                                                            {{order.order_state_name}}
                                                        </span>
                                                    </v-card-text>
                                                </v-card>
                                            </div>
                                            <div class="col-sm-12 col-md-4 col-lg-2 col-xl-2">
                                                <v-card height="131px" outlined tile >
                                                    <v-card-text style="height: 100%" class="d-flex flex-column justify-space-between">
                                                        <div class="d-flex flex-column">
                                                            <span class="text-subtitle-1 font-weight-medium">Shipping number</span>
                                                            <span class="text-body-2">From courier</span>
                                                        </div>
                                                        <span style="font-size: 1.2rem !important;" class="text-subtitle-1 black--text font-weight-medium">
                                                            {{order.shipping_number ? order.shipping_number : '-'}}
                                                        </span>
                                                    </v-card-text>
                                                </v-card>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col">
                                <div class="kt-portlet">
                                    <div class="kt-portlet__body kt-portlet__body--fit">
                                        <div class="row row-no-padding row-col-separator-xl">
                                            <div class="col-md-2">
                                                <div class="kt-widget1" :style="{padding: '0.9rem', background: orderHistory.find(e => e.order_state.type == 'DEFAULT_IN_OMS') ? '#39C408':''}">
                                                    <div class="kt-widget1__item">
                                                        <div class="kt-widget1__info">
                                                            <h3 :class="['kt-widget1__title', orderHistory.find(e => e.order_state.type == 'DEFAULT_IN_OMS') ? 'kt-shape-font-color-1':'']">Ingresado</h3>
                                                            <span class="kt-widget1__desc kt-shape-font-color-1">{{orderHistory.find(e => e.order_state.type == 'DEFAULT_IN_OMS') ? $moment(orderHistory.find(e => e.order_state.type == 'DEFAULT_IN_OMS').created_at).format('DD-MM-YYYY HH:mm') :''}}</span>
                                                        </div>
                                                        <span :class="['kt-widget1__number', orderHistory.find(e => e.order_state.type == 'DEFAULT_IN_OMS') ? 'kt-shape-font-color-1':'']" style="font-size: 3em;"><i class="la la-check"></i></span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-md-2">
                                                <div class="kt-widget1" :style="{padding: '0.9rem', background: orderHistory.find(e => e.order_state.type == 'DEFAULT_IN_PACKAGING') ? '#39C408':''}">
                                                    <div class="kt-widget1__item">
                                                        <div class="kt-widget1__info">
                                                            <h3 :class="['kt-widget1__title', orderHistory.find(e => e.order_state.type == 'DEFAULT_IN_PACKAGING') ? 'kt-shape-font-color-1':'']">Preparando</h3>
                                                            <span class="kt-widget1__desc  kt-shape-font-color-1">{{orderHistory.find(e => e.order_state.type == 'DEFAULT_IN_PACKAGING') ? $moment(orderHistory.find(e => e.order_state.type == 'DEFAULT_IN_PACKAGING').created_at).format('DD-MM-YYYY HH:mm') :''}}</span>
                                                        </div>
                                                        <span :class="['kt-widget1__number', orderHistory.find(e => e.order_state.type == 'DEFAULT_IN_PACKAGING') ? 'kt-shape-font-color-1':'']" style="font-size: 3em;"><i class="la la-dropbox"></i></span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-md-2">
                                                <div class="kt-widget1" :style="{padding: '0.9rem', background: orderHistory.find(e => e.order_state.type == 'DEFAULT_OUT_SHIPMENT') ? '#39C408':''}">
                                                    <div class="kt-widget1__item">
                                                        <div class="kt-widget1__info">
                                                            <h3 :class="['kt-widget1__title', orderHistory.find(e => e.order_state.type == 'DEFAULT_OUT_SHIPMENT') ? 'kt-shape-font-color-1':'']">Listo para Enviar</h3>
                                                            <span class="kt-widget1__desc  kt-shape-font-color-1">{{orderHistory.find(e => e.order_state.type == 'DEFAULT_OUT_SHIPMENT') ? $moment(orderHistory.find(e => e.order_state.type == 'DEFAULT_OUT_SHIPMENT').created_at).format('DD-MM-YYYY HH:mm') :''}}</span>
                                                        </div>
                                                        <span :class="['kt-widget1__number', orderHistory.find(e => e.order_state.type == 'DEFAULT_OUT_SHIPMENT') ? 'kt-shape-font-color-1':'']" style="font-size: 3em;"><i class="la la-archive"></i></span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-md-2">
                                                <div class="kt-widget1" :style="{padding: '0.9rem', background: orderHistory.find(e => e.order_state.type == 'DEFAULT_IN_CARRIER') ? '#39C408':''}">
                                                    <div class="kt-widget1__item">
                                                        <div class="kt-widget1__info">
                                                            <h3 :class="['kt-widget1__title', orderHistory.find(e => e.order_state.type == 'DEFAULT_IN_CARRIER') ? 'kt-shape-font-color-1':'']">Entregado a transporte</h3>
                                                            <span class="kt-widget1__desc  kt-shape-font-color-1">{{orderHistory.find(e => e.order_state.type == 'DEFAULT_IN_CARRIER') ? $moment(orderHistory.find(e => e.order_state.type == 'DEFAULT_IN_CARRIER').created_at).format('DD-MM-YYYY HH:mm') :''}}</span>
                                                        </div>
                                                        <span :class="['kt-widget1__number', orderHistory.find(e => e.order_state.type == 'DEFAULT_IN_CARRIER') ? 'kt-shape-font-color-1':'']" style="font-size: 3em;"><i class="la la-exchange"></i></span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-md-2">
                                                <div class="kt-widget1" :style="{padding: '0.9rem', background: orderHistory.find(e => e.order_state.type == 'DEFAULT_ON_CARRIER') ? '#39C408':''}">
                                                    <div class="kt-widget1__item">
                                                        <div class="kt-widget1__info">
                                                            <h3 :class="['kt-widget1__title', orderHistory.find(e => e.order_state.type == 'DEFAULT_ON_CARRIER') ? 'kt-shape-font-color-1':'']">En Ruta</h3>
                                                            <span class="kt-widget1__desc  kt-shape-font-color-1">{{orderHistory.find(e => e.order_state.type == 'DEFAULT_ON_CARRIER') ? $moment(orderHistory.find(e => e.order_state.type == 'DEFAULT_ON_CARRIER').created_at).format('DD-MM-YYYY HH:mm') :''}}</span>
                                                        </div>
                                                        <span :class="['kt-widget1__number', orderHistory.find(e => e.order_state.type == 'DEFAULT_ON_CARRIER') ? 'kt-shape-font-color-1':'']" style="font-size: 3em;"><i class="la la-truck"></i></span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-md-2">
                                                <div class="kt-widget1" :style="{padding: '0.9rem', background: orderHistory.find(e => e.order_state.type == 'DEFAULT_IN_CUSTOMER') ? '#39C408':''}">
                                                    <div class="kt-widget1__item">
                                                        <div class="kt-widget1__info">
                                                            <h3 :class="['kt-widget1__title', orderHistory.find(e => e.order_state.type == 'DEFAULT_IN_CUSTOMER') ? 'kt-shape-font-color-1':'']">Entregado a cliente</h3>
                                                            <span class="kt-widget1__desc  kt-shape-font-color-1">{{orderHistory.find(e => e.order_state.type == 'DEFAULT_IN_CUSTOMER') ? $moment(orderHistory.find(e => e.order_state.type == 'DEFAULT_IN_CUSTOMER').created_at).format('DD-MM-YYYY HH:mm') :''}}</span>
                                                        </div>
                                                        <span :class="['kt-widget1__number', orderHistory.find(e => e.order_state.type == 'DEFAULT_IN_CUSTOMER') ? 'kt-shape-font-color-1':'']" style="font-size: 3em;"><i class="la la-flag-checkered"></i></span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col">
                                <b-tabs content-class="mt-3" fill>
                                    <b-tab title="Productos" active>
                                        <v-simple-table fixed-header height="300px">
                                            <template v-slot:default>
                                                <thead>
                                                <tr>
                                                    <th class="text-left">ID</th>
                                                    <th class="text-left">Nombre</th>
                                                    <th class="text-left">SKU</th>
                                                    <th class="text-left">EAN</th>
                                                    <th class="text-left">Cantidad</th>
                                                    <th class="text-left">Total</th>
                                                    <th class="text-left">Fecha ingreso al sistema</th>
                                                    <th class="text-left">Store</th>
                                                </tr>
                                                </thead>
                                                <tbody>
                                                <tr v-for="(item,index) in orderDetail" :key="index">
                                                    <td>{{ item.id }}</td>
                                                    <td>
                                                        <v-tooltip top>
                                                            <template v-slot:activator="{ on }">
                                                                <v-btn @click="queryStock(item.sku)" text color="primary" dark v-on="on">{{item.product}}</v-btn>
                                                            </template>
                                                            <span>Consultar stock</span>
                                                        </v-tooltip>
                                                    </td>
                                                    <td>{{ item.sku }}</td>
                                                    <td>{{ item.ean }}</td>
                                                    <td>{{ item.quantity }}</td>
                                                    <td>{{ item.total }}</td>
                                                    <td>{{ $moment(item.created_at).format('DD-MM-YYYY HH:mm') }}</td>
                                                    <td>{{ item.store.name }}</td>
                                                </tr>
                                                </tbody>
                                            </template>
                                        </v-simple-table>
                                    </b-tab>
                                    <b-tab title="Cliente">
                                        <v-list two-line subheader>
                                            <v-subheader style="font-size: 1.2rem;"><v-badge style="cursor: pointer;" icon="mdi mdi-pencil" @click.native="editarCliente" > Datos Cliente</v-badge></v-subheader>
                                            <v-list-item>
                                                <v-list-item-content>
                                                    <v-list-item-title>Nombre</v-list-item-title>
                                                    <v-list-item-subtitle>{{customer.fullname}}</v-list-item-subtitle>
                                                    <v-list-item-title>Email</v-list-item-title>
                                                    <v-list-item-subtitle>{{customer.email}}</v-list-item-subtitle>
                                                </v-list-item-content>
                                            </v-list-item>
                                            <v-subheader  style="font-size: 1.2rem;">Metadatos</v-subheader>
                                            <v-list-item>
                                                <v-list-item-content>
                                                    <v-list-item-title>Fecha creación</v-list-item-title>
                                                    <v-list-item-subtitle>{{customer.created_at}}</v-list-item-subtitle>
                                                    <v-list-item-title>Store</v-list-item-title>
                                                    <v-list-item-subtitle>{{customer.store.name}}</v-list-item-subtitle>
                                                </v-list-item-content>
                                            </v-list-item>
                                        </v-list>
                                    </b-tab>
                                    <b-tab title="Dirección">
                                        <v-list two-line subheader>
                                            <v-subheader style="font-size: 1.2rem;"><v-badge style="cursor: pointer;" icon="mdi mdi-pencil" @click.native="editarDireccion" > Datos Dirección</v-badge></v-subheader>
                                            <v-list-item>
                                                <v-list-item-content>
                                                    <v-list-item-title>ID</v-list-item-title>
                                                    <v-list-item-subtitle>{{address.id}}</v-list-item-subtitle>
                                                    <v-list-item-title>Calle</v-list-item-title>
                                                    <v-list-item-subtitle>{{address.street}}</v-list-item-subtitle>
                                                    <v-list-item-title>Número</v-list-item-title>
                                                    <v-list-item-subtitle>{{address.number}}</v-list-item-subtitle>
                                                    <v-list-item-title>Dpto</v-list-item-title>
                                                    <v-list-item-subtitle>{{address.dpto}}</v-list-item-subtitle>
                                                    <v-list-item-title>Ciudad</v-list-item-title>
                                                    <v-list-item-subtitle>{{address.city}}</v-list-item-subtitle>
                                                    <v-list-item-title>Provincia</v-list-item-title>
                                                    <v-list-item-subtitle>{{address.province}}</v-list-item-subtitle>
                                                    <v-list-item-title>Región</v-list-item-title>
                                                    <v-list-item-subtitle>{{address.state}}</v-list-item-subtitle>
                                                    <v-list-item-title>DNI</v-list-item-title>
                                                    <v-list-item-subtitle>{{address.dni}}</v-list-item-subtitle>
                                                    <v-list-item-title>Teléfono</v-list-item-title>
                                                    <v-list-item-subtitle>{{address.phone}}</v-list-item-subtitle>
                                                </v-list-item-content>
                                            </v-list-item>
                                            <v-subheader  style="font-size: 1.2rem;">Datos Relacionados</v-subheader>
                                            <v-list-item>
                                                <v-list-item-content>
                                                    <v-list-item-title>Cliente</v-list-item-title>
                                                    <v-list-item-subtitle>{{address.customer.fullname}} (ID: {{address.customer.id}})</v-list-item-subtitle>
                                                    <v-list-item-title>Store</v-list-item-title>
                                                    <v-list-item-subtitle>{{address.store.name}} (ID: {{address.store.id}})</v-list-item-subtitle>
                                                </v-list-item-content>
                                            </v-list-item>
                                        </v-list>
                                    </b-tab>
                                    <b-tab title="Acciones">
                                        <div class="row">
                                            <div class="col-md-6">
                                                <v-card>
                                                    <v-card-text  class="grey lighten-5">
                                                        <v-select
                                                            v-model="current_state"
                                                            :items="orderStatesList"
                                                            :rules="[v => !!v || 'Debes elegir un estado']"
                                                            label="Cambiar Estado del pedido"
                                                            required
                                                        ></v-select>
                                                    </v-card-text>
                                                    <v-card-text style=" position: relative">
                                                        <v-btn
                                                            absolute
                                                            dark
                                                            fab
                                                            top
                                                            right
                                                            color="pink"
                                                            @click="sendFormOrderStates"
                                                        >
                                                            <v-icon>mdi-content-save</v-icon>
                                                        </v-btn>
                                                    </v-card-text>
                                                </v-card>
                                                        
                                            </div>
                                            
                                        </div>
                                    </b-tab>
                                </b-tabs>
                                        
                            </div>
                        </div>
                        <div class="row">
                            <div class="col">
                                <v-card
                                    color="#385F73"
                                    dark
                                    min-width="250px"
                                >
                                    <v-card-title class="headline">Shipping number</v-card-title>
                                    <v-card-subtitle>{{order.shipping_number}}</v-card-subtitle>
                                </v-card>
                            </div>
                            <div class="col">
                                <v-card
                                    color="#385F73"
                                    dark
                                    min-width="250px"
                                >
                                    <v-card-title class="headline">Total productos</v-card-title>
                                    <v-card-subtitle>{{order.total_products}}</v-card-subtitle>
                                </v-card>
                            </div>
                            <div class="col">
                                <v-card
                                    color="#385F73"
                                    dark
                                    min-width="250px"
                                >
                                    <v-card-title class="headline">Total Shipping</v-card-title>
                                    <v-card-subtitle>{{order.total_shipping}}</v-card-subtitle>
                                </v-card>
                            </div>
                            <div class="col">
                                <v-card
                                    color="#385F73"
                                    dark
                                    min-width="250px"
                                >
                                    <v-card-title class="headline">Total descuentos</v-card-title>
                                    <v-card-subtitle>{{order.total_discount}}</v-card-subtitle>
                                </v-card>
                            </div>
                            <div class="col">
                                <v-card
                                    color="#385F73"
                                    dark
                                    min-width="250px"
                                >
                                    <v-card-title class="headline">Total pagado</v-card-title>
                                    <v-card-subtitle>{{order.total_paid}}</v-card-subtitle>
                                </v-card>
                            </div>
                            <div class="col">
                                <v-card
                                    color="#385F73"
                                    dark
                                    min-width="250px"
                                >
                                    <v-card-title class="headline">Fecha desde Marketplace</v-card-title>
                                    <v-card-subtitle>{{$moment(order.source_date).format('DD-MM-YYYY HH:mm')}}</v-card-subtitle>
                                </v-card>
                            </div>
                            <div class="col">
                                <v-card
                                    color="#385F73"
                                    dark
                                    min-width="250px"
                                >
                                    <v-card-title class="headline">Estado Orden</v-card-title>
                                    <v-card-subtitle>{{order.order_state_name}}</v-card-subtitle>
                                </v-card>
                            </div>
                        </div>
                    </v-card-text>
                </v-card>
            </div>
        </div>
        <v-dialog v-model="showDialogEditarDatosCliente" max-width="1000px">
            <v-card>
                <v-card-title>
                    <span>Editar Cliente #{{customer.id}}</span>
                </v-card-title>
                <v-card-text>
                    <v-form
                    ref="form"
                    lazy-validation
                    >
                        <v-text-field
                            v-model="customer.firstname"
                            :counter="200"
                            label="Nombre"
                            required
                        ></v-text-field>
                        <v-text-field
                            v-model="customer.lastname"
                            :counter="200"
                            label="Apellidos"
                            required
                        ></v-text-field>
                        <v-text-field
                            v-model="customer.email"
                            :rules="emailRules"
                            label="E-mail"
                            required
                        ></v-text-field>
                    </v-form>
                </v-card-text>
                <v-card-actions>
                    <v-btn
                        color="primary"
                        text
                        @click="showDialogEditarDatosCliente = false"
                        >
                        Cancelar
                    </v-btn>
                    <v-btn
                        color="success"
                        text
                        @click="sendFormDatosCliente"
                        >
                        Guardar
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-dialog v-model="showDialogEditarDatosDireccion" max-width="1000px">
            <v-card>
                <v-card-title>
                    <span>Editar Dirección #{{address.id}}</span>
                </v-card-title>
                <v-card-text>
                    <v-form
                    ref="form"
                    
                    lazy-validation
                    >
                        <v-text-field
                            v-model="address.street"
                            :rules="defaultRequiredRules"
                            :counter="200"
                            label="Calle"
                            required
                        ></v-text-field>
                        <v-text-field
                            v-model="address.number"
                            :rules="defaultRequiredRules"
                            :counter="200"
                            label="Número"
                            required
                        ></v-text-field>
                        <v-text-field
                            v-model="address.dpto"
                            :rules="defaultRequiredRules"
                            label="Dpto"
                            required
                        ></v-text-field>
                        <v-text-field
                            v-model="address.city"
                            :rules="defaultRequiredRules"
                            label="Ciudad"
                            required
                        ></v-text-field>
                        <v-text-field
                            v-model="address.province"
                            :rules="defaultRequiredRules"
                            label="Provincia"
                            required
                        ></v-text-field>
                        <v-text-field
                            v-model="address.state"
                            :rules="defaultRequiredRules"
                            label="Región"
                            required
                        ></v-text-field>
                        <v-text-field
                            v-model="address.dni"
                            :rules="defaultRequiredRules"
                            label="DNI"
                            required
                        ></v-text-field>
                        <v-text-field
                            v-model="address.phone"
                            :rules="defaultRequiredRules"
                            label="Teléfono"
                            required
                        ></v-text-field>
                    </v-form>
                </v-card-text>
                <v-card-actions>
                    <v-btn
                        color="primary"
                        text
                        @click="showDialogEditarDatosDireccion = false"
                        >
                        Cancelar
                    </v-btn>
                    <v-btn
                        color="success"
                        text
                        @click="sendFormDatosDireccion"
                        >
                        Guardar
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-snackbar
            v-model="snackbarStock"
            :timeout="-1"
            >
            Stock actual: {{ snackbarStockQuantity }}

            <template v-slot:action="{ attrs }">
                <v-btn
                color="blue"
                text
                v-bind="attrs"
                @click="copyToClipboard(snackbarStockQuantity)"
                >
                    <v-icon left>
                        mdi-content-copy
                    </v-icon>
                    Copiar
                </v-btn>
                <v-btn
                color="green"
                rounded
                v-bind="attrs"
                @click="snackbarStock = false"
                >
                Ok
                </v-btn>
            </template>
        </v-snackbar>
        <div class="container" v-show="false">
            <input type="text" v-model="contentForCopy">
        </div>
    </v-app>
</template>

<script>
import { SET_BREADCRUMB } from "@/store/breadcrumbs.module";
export default {
    data(){
        return{
            showDialogEditarDatosCliente: false,
            showDialogEditarDatosDireccion: false,
            orden_id: undefined,
            order: {
                address:null,
                address_id:null,
                courier_tracking_id:null,
                created_at:null,
                current_state:null,
                customer_id:null,
                id:null,
                order_reference:null,
                order_state_name:null,
                process_order:null,
                province:null,
                shipping_number:null,
                source:null,
                source_date:null,
                source_id:null,
                state:null,
                store_id:null,
                total_discount:null,
                total_paid:null,
                total_products:null,
                total_shipping:null,
                updated_at: null,
                warehouse_tracking_id: null,
            },
            customer: {
                created_at:null,
                email:null,
                firstname:null,
                fullname:null,
                id:null,
                lastname:null,
                store:{},
                store_id:null,
                updated_at:null,
            },
            address: {
                city:null,
                created_at:null,
                customer:{},
                customer_id:null,
                dni:null,
                dpto:null,
                id:null,
                number:null,
                phone:null,
                province:null,
                state:null,
                store:{},
                store_id:null,
                street:null,
                updated_at:null,
            },
            source: {
                id:null,
                name:null,
                type_id:null
            },
            orderHistory: [],
            histories: undefined,
            orderDetail: undefined,
            statusAll: undefined,
            emailRules: [
                value => !!value || 'Requerido.',
                value => (value || '').length <= 200 || 'Máximo 200 caracteres',
                value => {
                const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                return pattern.test(value) || 'Email inválido.'
                },
            ],
            defaultRequiredRules: [
                v => !!v || 'Este campo es obligatorio',
            ],
            current_state: undefined,
            orderStatesList: [],
            snackbarStock: false,
            snackbarStockQuantity: 0,
            contentForCopy: '',
        }
    },
    methods: {
        fetchData(){
            var vm = this;
            this.axios({
                //url: 'http://oldoms.testing.fullkom.com/api/orders/'+this.$route.params.orden
                url: 'orders/ref/'+this.$route.params.ref
            }).then( response => {
                vm.orden_id = response.data.order.id;
                vm.$router.push({name: 'orden', params: {orden: response.data.order.id} });
                vm.order = response.data.order;
                vm.customer = response.data.customer;
                vm.address = response.data.address;
                vm.source = response.data.source;
                vm.orderHistory = response.data.orderHistory;
                vm.histories = response.data.histories;
                vm.orderDetail = response.data.orderDetail;
                vm.statusAll = response.data.statusAll;
                vm.current_state = response.data.order.current_state
                vm.fetchOrderStates();
            }).catch( error => {
                console.log(error);
                vm.push({name: '404'});
            });
        },
        queryStock(sku)
        {
            var vm = this;
            this.axios({
                url: 'order/products/'+sku+'/query_stock'
            }).then( response => {
                vm.snackbarStockQuantity = response.data;
            }).catch( error => {
                vm.snackbarStockQuantity = 0;
            }).finally( () => {
                vm.snackbarStock = true;
            });
        },
        copyToClipboard(content)
        {
            this.contentForCopy = String(content);
            this.$copyText(this.contentForCopy);
        },
        editarCliente()
        {
            this.showDialogEditarDatosCliente = true;
        },
        editarDireccion()
        {
            this.showDialogEditarDatosDireccion = true;
        },
        sendFormDatosCliente(){
            console.log('actualiazndo datos cliente');
            
            var vm = this;
            this.axios({
                url: 'orders/customer/'+this.customer.id,
                method: 'POST',
                data: {
                    firstname: this.customer.firstname,
                    lastname: this.customer.lastname,
                    email: this.customer.email,
                }
            }).then( (response) => {
                vm.$bvToast.toast(response.data, {
                    title: `Información`,
                    variant: 'success',
                    solid: true,
                    toaster: 'b-toaster-bottom-center'
                });
                vm.fetchData();
                vm.showDialogEditarDatosCliente = false;
            }).catch( error => {
                console.log(error);
                vm.showDialogEditarDatosCliente = false;
            });
        },
        sendFormDatosDireccion(){
            console.log('actualiazndo datos direccion');
            var vm = this;
            this.axios({
                url: 'orders/address/'+this.address.id,
                method: 'POST',
                data: this.address
            }).then( (response) => {
                vm.$bvToast.toast(response.data, {
                    title: `Información`,
                    variant: 'success',
                    solid: true,
                    toaster: 'b-toaster-bottom-center'
                });
                vm.fetchData();
                vm.showDialogEditarDatosDireccion = false;
            }).catch( error => {
                console.log(error);
                vm.showDialogEditarDatosDireccion = false;
            });
        },
        fetchOrderStates(){
            var vm = this;
            this.axios({
                url: 'orders/states/data_select_input?store='+this.order.store_id
            }).then( response => {
                vm.orderStatesList = response.data;
            }).catch( (error) => {
                console.log(error);
            });
        },
        sendFormOrderStates()
        {
            var vm = this;
            this.axios({
                url: 'orders/'+this.orden_id+'/state',
                method: 'POST',
                data: {
                    state: this.current_state
                }
            }).then( () => {
                vm.$bvToast.toast('Estado actualizado correctamente', {
                    title: `Información`,
                    variant: 'success',
                    solid: true,
                    toaster: 'b-toaster-bottom-center'
                });
                vm.fetchData();
            }).catch( (error) => {
                console.log(error);
            });
        }
    },
    created(){
        
        this.$store.dispatch(SET_BREADCRUMB, [
            { title: "Dashboard Ordenes", route: "/ordenes" },
            { title: "Orden REF "+this.$route.params.ref, route: "/ordenes/reF/"+this.$route.params.ref },
        ]);
        this.fetchData();
    },
    watch: {
        $route(to, from) {
         this.fetchData();
        }
    }
}
</script>